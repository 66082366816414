<template>
    <div class="print">
        <van-nav-bar title="2021-05-08" left-text="">
            <template #right>
                <van-uploader :after-read="e=>afterRead(e)" v-model="printImg" :preview-image="false">
                    <van-icon name="plus" size="24px" color="#00cca2"/>
                </van-uploader>
            </template>
        </van-nav-bar>
        <div class="print-list">
            <van-list
                v-model="loading"
                :finished="finished"
                :finished-text="listFinishedText"
                @load="onLoad"
                >
                <div class="print-list-item" v-for="(item,index) in imgList" :key="index">
                    <div class="print-list-title">{{item.print_year}} 第{{item.print_week}}</div>
                    <div class="print-list-img">
                        <van-image
                        v-for="(it,j) in item.origin"
                        :key="j"
                        @click="toPreview(it)"
                        width="65"
                        height="65"
                        fit="contain"
                        :src="it.print_pic"
                        />
                    </div>
                </div>
            </van-list>
            <van-empty v-if="list.length == 0 && !loading" description="暂无相关数据~" />
        </div>
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
    import upload from '@/utils/upload'
    import { NavBar, Empty, Uploader, ImagePreview } from 'vant'
    import Loadings from '@/components/loading'
    export default {
        name:'print',
        data() {
            return {
                loading:false,
                finished: false,
                searchLoading: false,
                keyword: '',
                list:[],
                imgList:[],
                listFinishedText: '没有更多了',
                pageSize: 50,
                pageCurrent: 1,
                printImg:[],
            }
        },
        components: {
            [Empty.name]:Empty,
            [NavBar.name]:NavBar,
            [Uploader.name]:Uploader,
            loadings:Loadings,
        },
        methods: {
            getList(){
                this.$api.custom_huidong_week_print({ 'per-page': this.pageSize, page: this.pageCurrent})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    this.list.forEach(item=>{
                        item.created_at = `${item.print_year}-${item.print_week}`
                    })
                    this.imgList = this.albumArray(this.list)
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                })
            },
            onLoad() {
                this.getList()
            },
            afterRead(file){
                this.$toast.loading({
                    message: '上传中...',
                    loadingType: 'spinner',
                })
                upload({ file: file.file })
                .then(res => {
                    this.addPic(res)
                })
                .catch(err => {
                    this.$toast.clear()
                })
            },
            addPic(data){
                this.$api.custom_huidong_add_print({print_pic:data.fileName})
                .then(res=>{
                    this.$toast.clear()
                    this.pageCurrent = 1
                    this.getList()
                })
                .catch(err=>{
                    this.$toast.clear()
                })
            },
            toPreview(item){
                ImagePreview([item.print_pic])
            },
            albumArray(beforeData){
                let tempArr = []
                let afterData = []
                for (let i = 0; i < beforeData.length; i++) {
                    if (tempArr.indexOf(beforeData[i].created_at) === -1) {
                        afterData.push({
                        print_year:beforeData[i].print_year,
                        print_week:beforeData[i].print_week,
                        created_at: beforeData[i].created_at,
                        origin: [beforeData[i]]
                        })
                        tempArr.push(beforeData[i].created_at);
                    } else {
                        for (let j = 0; j < afterData.length; j++) {
                        if (afterData[j].created_at == beforeData[i].created_at) {
                            afterData[j].origin.push(beforeData[i]);
                            break;
                        }
                        }
                    }
                }
                let afterDataSort = this.sortKey(afterData,'created_at')
                console.log(afterDataSort,'afterDataSort')
                return afterDataSort
            },
            sortKey(array, key) {
                return array.sort(function(a, b) {
                    var x = a[key];
                    var y = b[key];
                    return x > y ? -1 : x < y ? 1 : 0;
                })
            },
        },
    }
</script>

<style lang="less" scoped>
    .print{
        min-height: 100vh;
        background: #fff;
    }
    .print-list{
        &-title{
            text-align: center;
            padding: 10px 0;
        }
        &-img{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .van-image{
                margin: 10px;
            }
        }
    }
</style>